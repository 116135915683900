import React, { forwardRef, type HTMLProps } from 'react'
import { Label as LabelReactAria } from 'react-aria-components'
import { cn } from '#app/utils/tailwind-merge.ts'

const Label = forwardRef<HTMLLabelElement, HTMLProps<HTMLLabelElement>>(({ className, ...props }, ref) => {
	return <LabelReactAria ref={ref} className={cn('label', className)} {...props} />
})

Label.displayName = 'Label'

export { Label }
