import * as React from 'react'
import { cn } from '#app/utils/tailwind-merge.ts'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
	return (
		<input
			type={type}
			className={cn(
				'border-input placeholder:text-muted-foreground aria-[invalid]:border-input-invalid bg-background ring-offset-background focus-visible:ring-ring flex h-10 w-full rounded-md border py-2 px-3 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:cursor-not-allowed disabled:opacity-50',
				className,
			)}
			ref={ref}
			{...props}
		/>
	)
})
Input.displayName = 'Input'

export { Input }
